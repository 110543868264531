$(document).ready(function() {

	var app = [],
		mobileMenu = $('#mobile-nav');

	$(document).foundation();

	$('#mobile-nav-toggle').on('click', function() {
		mobileMenu.slideToggle();
	});

	$('#modal-bg').click(function() {
		$('#modal-bg').removeClass('active');
		$('.modal').removeClass('open');
	});

	$('#open-promotional-share-modal').click(function(e) {
		e.preventDefault();
		var recipient = $('#promo-reciptient');

		if(recipient.val().length > 0) {
			$('#recipient_email').val(recipient.val());
		}

		$('#modal-bg').addClass('active');
		$('#modal-share-promotion').addClass('open');
	});


/* PRIJZEN */

	 var subtotal = 15;

	 $('.module-picker-row').on('click', function() {
	    if ($(this).hasClass('active')) {
	        $(this).removeClass('active');
            subTotaal('min');
        } else {
	        $(this).addClass('active');
	        subTotaal('plus');
	    }
	 });

	 function subTotaal(status) {
	    if(status == 'min') {
	        subtotal = subtotal - 15;
	    } else {
	        subtotal = subtotal + 15;
	    }

        document.getElementById("total-price").innerHTML = '€ ' + subtotal + ',- p.m.';
	 }

/* PRIJZEN *//*

	var subtotal = 15;
	var moduleCount = 1;
	var price;

	$('.module-picker-row').on('click', function() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			subtotaal('min');
			countModules('min');
			kortingBerekening();

		} else {
			$(this).addClass('active');
			subtotaal('plus');
			countModules('plus');
			kortingBerekening();
		}
	});

	function subtotaal(status) {
		if(status == 'min') {
			subtotal = subtotal - 15;
		} else {
			subtotal = subtotal + 15;
		}
	}

	function countModules(status) {
		if(status == 'min') {
			moduleCount--;
		} else {
			moduleCount++;
		}

		document.getElementById("price-count").innerHTML = '€ ' + subtotal + ',- (' + moduleCount + ')';
	}

	function kortingBerekening() {
		if(moduleCount < 4){
			document.getElementById("discount-count").innerHTML = 'Geen';
			totaalBerekening();
		}
		if(moduleCount >= 4){
			document.getElementById("discount-count").innerHTML = '10% (4)';
			totaalBerekening();
		}
	}

	function totaalBerekening() {
		if(moduleCount < 4) {
			price = subtotal;
			document.getElementById("total-price").innerHTML = '€ ' + price + ',-';
		}
		if(moduleCount == 4) {
			price = subtotal * 0.9;
			document.getElementById("total-price").innerHTML = '€ ' + price + ',-';
		}

		if(moduleCount > 4) {
			price = (price * 1) + 15;
			document.getElementById("total-price").innerHTML = '€ ' + price + ',-';
		}
	}
*/



	/* TESTIMONIAL SLIDER */

	$.fn.slider = function(){
		var slider = $(this),
				slides = [],
				currentSlide,
				timer;

		var init = function(){
			currentSlide = -1;
			var sliderSlides = slider.children('.slide');

			sliderSlides.each(function( i ) {
				var slide = $(this);
				resetSlide( slide );
				slides.push( slide );
			});

			doSlide(1);
		}

		var doSlide = function(toPos)
		{
			clearTimeout(timer);
			var lastSlide = $(document.createElement("div"));

			if( currentSlide > -1 ) {
				lastSlide = slides[currentSlide];
				animateOut( lastSlide );
				lastSlide.children(':animated').promise().done(function() {
					resetSlide( lastSlide );
				});
			}

			lastSlide.children(':animated').promise().done(function() {
				// console.log('Finished sliding out!');

				var newSlide = currentSlide + toPos;

				if (newSlide == slides.length) {
					currentSlide = 0;
				} else if (newSlide < 0) {
					currentSlide = slides.length - 1;
				} else {
					currentSlide = newSlide;
				}

				var slide = slides[currentSlide];

				animateIn( slide );

				// Wait for it to finish all animations
				slide.children(':animated').promise().done(function() {
					// console.log('Finished sliding in!');
					timer = setTimeout( function() {
						doSlide(1)
					}, 6000);
				});
			});
		}

		var animateIn = function (slide)
		{
			// Show the slide
			slide.fadeIn(800);

			// Do something for each piece of content
			slide.children('.slider-item').each(function( i )
			{
				var contentItem = $(this);

				if( contentItem.data('animation') == 'slide' )
				{
					contentItem.animate({
						opacity: 1,
						left: ( ( slider.width() / 2 ) - ( ( contentItem.width() / 2 ) - contentItem.data('slide-x') ) )
					}, contentItem.data('slide-speed-in'), 'easeInOutSine');
				}
				else if ( contentItem.data('animation') == 'fade' )
				{
					// Reset positioning
					contentItem.css({
						'top': '',
						'bottom': '',
						'left': '',
						'right': ''
					});

					contentItem.animate({
						opacity: 1
					}, contentItem.data('slide-speed-in'));
				}
			});
		}

		var animateOut = function (slide)
		{
			// Do something for each piece of content
			slide.children('.slider-item').each(function( i )
			{
				var contentItem = $(this);

				if( contentItem.data('animation') == 'slide' )
				{
					contentItem.animate({
						opacity: 0,
						left: "+=40%",
					}, contentItem.data('slide-speed-out'), 'easeInOutSine');
				}
				else if( contentItem.data('animation') == 'fade' )
				{
					contentItem
						.animate({
							opacity: 0
						}, contentItem.data('slide-speed-out'));
				}
			});
		}

		var resetSlide = function (slide)
		{
			// console.log('Resetting slide..');
			slide.fadeOut(800);

			slide.children('.slider-item').each(function( i )
			{
				var contentItem = $(this);

				// Reset positioning
				contentItem.css('top', '').css('bottom', '');

				contentItem.css('left', -(contentItem.width()));
				contentItem.css('opacity', 0);
			});
		}

		slider.children('.slider-prev').on('click', function() {
			var slide = slides[currentSlide];
			slide.children('.slider-item').each(function( i )
			{
				var contentItem = $(this);
				contentItem.stop(true, false);
			});

			doSlide(-1);
		});

		slider.children('.slider-next').on('click', function() {
			var slide = slides[currentSlide];
			slide.children('.slider-item').each(function( i )
			{
				var contentItem = $(this);
				contentItem.stop(true, false);
			});

			doSlide(1);
		});

		init(); // Initialize
	};
});